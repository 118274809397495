.css-nvf14r-ToastContainer {
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 8px;
  position: fixed;
  z-index: 1300! important;
  top: 0;
  right: 0;
}
.leaveEllipsis {
  width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  /* This add an ellipsis i.e ... if the text increases too long */
  text-overflow: ellipsis;
  position: relative;
  display: block;
  height: 70px;
}
.leaveEllipsisspan {
  position: absolute;
  cursor: pointer;
  left: 10px;
  color: #ffc900;
  font-weight: bold;
  bottom: 0px;
}
.taskDesc{
  width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
  height: 70px;
}
.taskDescspan{
  position: absolute;
  cursor: pointer;
  left: 10px;
  color: red;
  font-weight: bold;
  bottom: 0px;
}

/* 
Prifile Section */





/* 
Change Password Section */
/* Base CSS */
.alignleft {
    float: left;
    margin-right: 15px;
}
.alignright {
    float: right;
    margin-left: 15px;
}
.aligncenter {
    display: block;
    margin: 0 auto 15px;
}
a:focus { outline: 0 solid }
img {
    max-width: 100%;
    height: auto;
}
.fix { overflow: hidden }
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 15px;
    font-weight: 700;
}
html,
body { height: 100% }

a {
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: #333;
}
a:hover { text-decoration: none }



.search-box{margin:80px auto;position:absolute;}
.caption{margin-bottom:50px;}
.loginForm input[type=text], .loginForm input[type=password]{
	margin-bottom:10px;
}
.loginForm input[type=submit]{
	background:#04defb;
	color:#fff;
	font-weight:700;
	
}


#pswd_info {
	background: #dfdfdf none repeat scroll 0 0;
	color: #fff;
	left: 20px;
	position: absolute;
	top: 115px;
}
#pswd_info h4{
    background: black none repeat scroll 0 0;
    display: block;
    font-size: 14px;
    letter-spacing: 0;
    padding: 17px 0;
    text-align: center;
    text-transform: uppercase;
}
#pswd_info ul {
    list-style: outside none none;
}
#pswd_info ul li {
   padding: 10px 45px;
}



.valid {
	background: rgba(0, 0, 0, 0) url("https://s19.postimg.org/vq43s2wib/valid.png") no-repeat scroll 2px 6px;
	color: green;
	line-height: 21px;
	padding-left: 22px;
}

.invalid {
	background: rgba(0, 0, 0, 0) url("https://s19.postimg.org/olmaj1p8z/invalid.png") no-repeat scroll 2px 6px;
	color: red;
	line-height: 21px;
	padding-left: 22px;
}


#pswd_info::before {
    background: #dfdfdf none repeat scroll 0 0;
    content: "";
    height: 25px;
    left: -13px;
    margin-top: -12.5px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 25px;
}
#pswd_info {
    display:none;
}
/* .commoncomponent_cardcss {
  margin: 74px 10px 10px 10px;
  transition: all 0.5s ease;
  padding: 15px;
} */

.login-page .forget-password-link {
  color: #17b978;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.login-page .forget-password-link:hover {
  color: #149e66;
}
