body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.newhead{
  margin: 40px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.newhead h4{
  font-family: 'Ubuntu', sans-serif;
  font-size: 35px;
}

.newhead div{
  background-color: #347EFF;
  color: white;
 cursor: pointer;
  width: 150px;
  height: 50px;
  border-radius: 8px;
  display: flex;
}

.newhead div:hover{
  transform: scale(1.1,1.1);
}

.newhead div h6{
  width: 70%;
  height: 100%;
 display: flex;
 text-transform: uppercase;
 align-items: center;
 font-family: 'Ubuntu',sans-serif;
 justify-content: center;
  
 }
.newhead div p{
 width: 30%;
 display: flex;
 height: 100%;
 font-size: 20px;
 margin: 0;
 font-family: 'Ubuntu',sans-serif;
 border-bottom-right-radius:8px ;
 border-top-right-radius:8px ;
 align-items: center;
 justify-content: center;
 font-weight: bold;
  background-color: #74a7ff;
}

.closei{
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.modalContainer{
  padding: 10px 60px;
}

.modalContainer h1{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 20px 0px;
  text-align: center;
  opacity: 0.9;
}

.modalContainer h3{
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  color: rgb(98, 97, 97);
  
}

.modalContainer span{
  
  font-family: 'Poppins', sans-serif;
  color: rgb(255, 81, 0);
}

.modalContainer form div{
  
  margin-top: 20px;
}

.modalContainer p{
  font-size: 10px;
}

.modalinput{
  outline: none;
  width: 420px;
  color: rgb(101, 101, 101);
  border: 1px solid gray;
  padding: 10px 20px;
  border-radius: 5px;
}

.modalbtn{
  outline: none;
  border: none;
  min-width: 120px;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 15px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  transition: 0.3s;
}

.backbtn{
  color: rgb(0, 139, 231);
  background-color: white;
  border: 1px solid gray;
}

.submitbtn{
  color: white;
  background-color: rgb(0, 139, 231);
}

/* 
MainDashboard Section */
.maindash_body{
  max-width: 1000px;
}
.grid-four{
 
  display: grid;
  grid-template-columns: auto auto auto auto ;
  row-gap: 30px;
 
  padding: 40px 30px;
}

.grid-four .card1{
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  width: 230px;
  border-radius: 15px;
}

.grid-four .card1 h1{
  font-size: 30px;
}

.grid-four .card1 h2{
  font-size: 16px;
}

.tbldiv{
 margin-top: 50px;
 display:flex;
 justify-content:space-around;
}

.tablediv{
  width:600px;
  padding: 20px;
  border: 1px solid grey;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 2px grey;
}

.topmargin{
  margin-top: 10px;
}


/* 
============================================================================
      PROFILE SECTION START
============================================================================ */

.profile_header{
 
  background-color: #ffffff;
}

.profile_header p{
  padding: 20px;
  color: white;
  font-family: 'Ubuntu',sans-serif;
}
 

.profile_main{
  display: grid;
  grid-template-columns: auto auto;
  /* position: absolute; */
  gap: 60px;
  /* margin-top: 20px; */
  
  
  
}

.company_profile_main{
  display: grid;
  grid-template-columns: auto auto;
  /* position: absolute; */
  margin-top: 20px;
  
  
  
}

.profile_main_left{
  /* position: absolute;
  top: -50px; */
  padding: 10px 35px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
   background-color: white;
   min-width: 650px;
   height: 100%;
   border: 1px solid rgba(60, 64, 67, 0.3) ;
  
}

.profile_main_left h1{
  font-size: 25px;

  font-family: 'Ubuntu',sans-serif;
}

.profile_main_left h3{
  font-size: 14px;
  color: #323232;
  font-weight: 400;
  font-family: 'Ubuntu',sans-serif;
}
.profilefield_company{
  padding: 4px 20px;
  background-color: #E3E3E3;
  border-radius: 20px;
  border: none;
  width: 100%;
  outline: none;
}
.profilefield{
  padding: 4px 20px;
  width: 100%;
  color: #4e4f50;
  background-color: #E6E6E6;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  outline: none;
}

.professionfield{
  padding: 4px 20px;
  width: 100%;
  color: #4e4f50;
  background-color: #E6E6E6;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  outline: none;
}

.profile_grid_three{
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
}

.profile_grid_two{
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  row-gap: 30px;

}

.profile_main_right{
  position: relative;
  width: 300px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 5px;
 min-height: 490px;
  border: 1px solid rgba(60, 64, 67, 0.3) ;
  
  background-color: white;
 
}

.company_main_right{
  position: relative;
  width: 300px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /* height: 100%; */
  border: 1px solid rgba(60, 64, 67, 0.3) ;
  border-right: none;
  min-height: 500px;
  background-color: white;
 
}





.circle_image{
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #e1e1e1;
  padding: 1px;
  text-align: center;
  position: relative;
}

.circle_image img{
  
  height: 100%;
  width: 100%;
  border-radius: 50%;
  
}

.edit-pic{
  position: absolute;
  right: -10px;
  bottom: 20px;
  background-color: white;
  color: rgb(2, 96, 173);
  padding: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  box-shadow: 2px 0px 2px 1px gray;
  align-items: center;
  justify-content: center;
}

.profile_name{
  font-size: 14px;
  color: rgb(25, 125, 255);
  text-align: center;
  margin-top: 20px;
  font-family: 'Ubuntu',sans-serif;
}
.profile_id{
  font-size: 20px;
 
  font-family: 'Ubuntu',sans-serif;
}

.profile_desc{
  margin-top: 20px;
  font-size: 15px;
 
  font-family: 'Ubuntu',sans-serif;
}

.update-profile{
  background-color: #347EFF;
  color: white;
  cursor: pointer;
  padding: 10px 30px;
  max-width: 150px;
  border: none;
  outline: none;
  margin-top: 10px;
  border-radius: 5px;
  
}

.update-password{
  background-color: #347EFF;
  color: white;
  cursor: pointer;
  padding: 10px 30px;
  width: 50%;
  border: none;
  outline: none;
  margin-top: 40px;
  border-radius: 5px;
}


.update-profile:hover{
  transform: scale(1.1,1.1);
}


.social-media{
  position: absolute;
  width: 100%;
  bottom: 70px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.social-media-icons{
  color: #5793fb;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px 1px #347EFF;
 
}
.social-media-icons:hover{
  transform: scale(1.1,1.1);
  color: #02307e;
}

.company_profile_photo{
  position: relative;
  margin: 25px 35px;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}

.star{
  color: rgb(255, 196, 0);
  
}

/* 
============================================================================
      PROFILE SECTION END
============================================================================ */

/*
=============================================================================
Chat Section Start
============================================================================  */



