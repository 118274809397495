.maindash_header {
  background: transparent;
  margin-top: -30px;
}
.maindash_headercomponent {
  display: flex;
  justify-content: space-between;
}
.office_dropdown {
  margin-top: -10px !important;
  margin-left: 5px !important;
}
.bodytop {
  margin-top: 20px;
}
.bodytop_top {
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  padding: 20px;
  /* margin-left: 5px;
  margin-right: 5px; */
  margin: 5px;
  flex: 0.25;
}
.bodytop_topitem {
  display: flex;
  align-items: center;
}
.body_top {
  min-height: 150px;
  border: 1px solid #d5d5db;
  padding: 20px;
  flex: 0.55 1;
  border-radius: 5px;
  overflow: auto;
}
.body_topright {
  min-height: 150 px;
  border: 1px solid #d5d5db;
  padding: 20px;
  flex: 0.55 1;
  margin-left: 5px;
  border-radius: 5px;
  overflow: auto;
}
.bootstrapcoldashboard {
  padding: 20px !important;
}
.body_top2 {
  min-height: 300px;
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  flex: 1;
}
.bodymiddle {
  height: 400px;
  overflow-y: auto;
  border: 1px solid #d5d5db;
  border-radius: 5px;
  padding: 20px;
}
.bodytop_icon {
  margin-right: 15px;
}
.bodytop_icontext {
  margin-left: 30px;
}
.application1 {
  flex: 0.1;
  padding: 10px;
  border-right: 1px solid black;
  margin: 5px !important;
}
.application2 {
  flex: 0.7;
  margin: 5px !important;
}
.applicationellipsis {
  width: 100px !important;
  white-space: nowrap;
  overflow: hidden;
  /* This add an ellipsis i.e ... if the text increases too long */
  text-overflow: ellipsis;
}
.paddingbutton {
  padding: 0 !important;
}
.bodymiddle_reminder {
  height: 330px;
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  padding: 20px;
  margin: 5px;
  flex: 0.55;
}
.box {
  /* width: 80%; */
  /* border: 2px dashed; */
  height: 250px;
  overflow-y: scroll;
  scroll-behavior: smooth; /* <--- */
}
.dashboardmiddle_below {
  display: flex;
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
}
.dashboardmiddle_belowone {
  height: 400px;
  overflow-y: auto;
  border: 1px solid #d5d5db;
  border-radius: 5px;
  margin-left: 5px;
  padding: 20px;
  min-width: 200px;
  overflow: auto;
}
.bodytop_innercontainer {
  border: 1px solid #d5d5db;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin: 0 5px;
  margin-right: 0;
}
.bodytop_innercontainer h4 {
  color: #2a4184;
  margin-bottom: 0 !important;
}
.bodytop_innercontainer h1 {
  font-size: 60px;
  color: #6cc1dd;
}
.maindashboard_select {
  margin-bottom: 10px;
}
